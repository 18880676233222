import "./popup.scss";
import { useState } from "react";
// import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import { Link } from "react-router-dom";
// import Logo from "../../assets/wehaulogo2.png";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

const Popup = ({ setShowModal }) => {
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState();

  const [page, setPage] = useState("customer");
  const history = useHistory();

  const formData = {
    email,
    password,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await axios.post(
        `https://app.wehaul247.com/api/v1/auth/${page}/login`,
        formData
      );
      console.log(data, data.token);
      setLoading(false);
      await localStorage.setItem(
        `WEHAUL_${page.toUpperCase()}_TOKEN`,
        data.token
      );
      window.location.href = `https://${page}.wehaul247.com`;
    } catch (error) {
      setLoading(false);
      console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div className="reglog">
      <div className="wrapperv">
        <div className="modalv">
          <i onClick={() => setShowModal(false)} className="fas fa-times"></i>
          {/* <h1>FROM</h1> */}
          <div className="cont">
            <p className="greet">Hello There 👋🏻, </p>
            <div className="sec">
                <p className="txtx">To complete your request process,</p>
                <br />
                <button onClick={() => window.location.href = "https://customer.wehaul247.com?test=wehaul"}>Sign Up Now <i className="fas fa-arrow-right"></i></button>
            </div>
            
            {/* <p className="txt">Wehaul247 would be live on the <span>24th, March 2022</span></p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
