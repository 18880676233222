import "./navbar.scss";
import Logo from "./wehaulogo2.png";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import Reglog from "../../pages/reglogModal/reglog";
import Sidebar from "../sidebar/sidebar";
import { Avatar, Box, Button, Circle, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../config";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { CheckCircleIcon } from "@chakra-ui/icons";



const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expansible, setExpansible] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [reqId, setReqId] = useState("");
  const [historyx, setHistoryx] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();


  const openNav = () => {
    setNavOpen(true);
  };
  const closeNav = () => {
    setNavOpen(false);
  };

 

  const history = useHistory();

  const trackRequest = async () => {
    setLoading2(true);

            try {
                const res = await axios.get(`${baseUrl}/tracking/track/${reqId}`)
                console.log("tracking history", res?.data?.data)
                setHistoryx(res?.data?.data)
                onClose()
                onOpen2();
            } catch(error) {
                console.log(error?.response?.data?.message)
                toast.error(error?.response?.data?.message)
            }
            finally {
              setLoading2(false);
            }
        }


  return (
    <div className="navbar">
      <div className="left">
        <img onClick={() => history.push("/")} src={Logo} alt="wehaul_logo" />
        <ul>
          <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
            <li>Home</li>
          </Link>
          <div className="mid">
            <li onClick={() => setExpansible(prev => !prev)}>Partner With Us<i className={expansible ? "fas fa-angle-down rotated" : "fas fa-angle-down"}></i></li>
            {expansible && (
              <ul className="expanse">
                  <Link onClick={() => setExpansible(false)} to="/driver" style={{ textDecoration: "none" }}><li>Become a Driver</li></Link>
                  {/* <li onClick={() => setExpansible(false)}>Become a Merchant</li>
                  <li onClick={() => setExpansible(false)}>Become an enterprise</li> */}
              </ul>
            )}
          </div>

          <li onClick={onOpen}>Track Request</li>
        </ul>
      </div>
      <div className="middle" style={{color: "#fff"}}>
        <p style={{width: "max-content",marginRight: "1rem", fontSize: "1.3rem"}} className="txt">Call us at:</p>
        <p style={{marginRight: "2rem", color: "#fff", width: "max-content", fontSize: "1.3rem", textDecoration: "underline"}} className="number">01-888-6060</p>
        <p style={{marginRight: "2rem", color: "#fff", width: "max-content", fontSize: "1.3rem", textDecoration: "underline"}} className="number">0916-0000-687</p>
      </div>
      <div className="right">
        <Link to="/driver">
          <button className="bcd">Become a Driver</button>
        </Link>
        {/* <p onClick={() =>  window.location.href = "https://customer.wehaul247.com/signup"} className="reg-log">
          Register
        </p> */}
        <p style={{marginLeft: "20px"}} onClick={() =>  setShowModal(true)} className="reg-log">
          Login/Register
        </p>
      </div>
      <div className="bar">
        {navOpen ? (
          <i onClick={closeNav} class="fas fa-times"></i>
        ) : (
          <i onClick={openNav} class="fas fa-bars"></i>
        )}
      </div>

      {showModal && <Reglog setRegLogModal={setShowModal} />}
      <Sidebar
        setShowModal={setShowModal}
        setNavOpen={setNavOpen}
        navOpen={navOpen}
      />


<Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>Track Request</ModalHeader>
            <ModalCloseButton _focus={{boxShadow: "none"}} />
            <ModalBody>
                <FormControl>
                    <Input onChange={e => setReqId(e.target.value.trim())} textTransform="uppercase" placeholder="Enter Request ID" />
                </FormControl>
            </ModalBody>

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>
                Close
                </Button>
                <Button type="sumbit" colorScheme='blue' w={130} onClick={trackRequest}>{loading2 ? <Spinner /> : "Find Request"}</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>

        <Drawer
            isOpen={isOpen2}
            placement='right'
            onClose={onClose2}
            // finalFocusRef={btnRef}
            size="md"
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader color="#344055">Delivery Status History</DrawerHeader>

            <DrawerBody>
                <Text pb={3} fontSize='24px' textTransform='uppercase' color="gray.800" fontWeight='600'>{historyx?.trackTitle}</Text>
                <Divider />
                <Flex mt={5} direction="row" alignItems="center">
                  <Heading color="gray.800" size="md" mr={3}>{historyx?.requestId}</Heading>
                  <Button size="xs" backgroundColor="#2a8960" color="#fff">Copy Request ID</Button>
                </Flex>
                <Text pb={4} mt={3} textAlign="left" lineHeight={1} size="xs" color="gray.500">Please copy and keep this code to revisit your request and track progress.</Text>
                <Divider />

                <Box pt={5}>
                  {historyx?.trackStages.map(({stageTitle}, index) => {
                    return (
                      <Box key={index}>
                        <Flex direction='row'>
                          <CheckCircleIcon marginTop="2px" mr={3} fontSize={20} color='green' />
                          <Text mb={10}>{stageTitle}</Text>
                        </Flex>
                        {/* <Divider width={40} transform="rotate(180deg)" color="gray.800" /> */}
                      </Box>
                      
                      
                    )
                  })}
                </Box>
                
            </DrawerBody>

            {/* <DrawerFooter>
                <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
                </Button>
                <Button colorScheme='blue'>Save</Button>
            </DrawerFooter> */}
            </DrawerContent>
        </Drawer>
    </div>
  );
};

export default Navbar;
