import './driverPrivacy.scss';
import { useState } from 'react';

const DriverPrivacy = () => {
    return (
        <div className="privacy">
            <h2>WeHaul Driver Privacy Policy</h2> <br /> <p> At wehaul (here after referred to as “us,” our” or “we”) we are committed to protecting your individual and organizational privacy. Our company’s privacy policy, currently applies to all clientele of our website () (here after refered to as Website”), the wehaul mobile /handheld device applications (“App”) or /and any of all services provided by us through the Website and/or App (“Service”), is specifically developed to explicate the information we collate and how we use it to revise and provide services rendered to continually give users a better and recommendable experience (“Privacy policy”). Some terms not expressly stated in this Privacy policy documents do have their meanings given in our Terms of Use located at our website. As always, our Privacy policy is  incorporated into, our Terms of Use.
By agreeing to the terms of this Privacy Policy in this document, you herby give consent to our collection, storage, use and disclosure of your personal information and other information as described in this Privacy Policy. <br />
We do not operate on the territory of member states of the European Union, of the European Economic Area, we do not store, collect or process personal data of citizens of member states of the European Union, European Economic Area.
This policy contains the following sections: Data Controller, Types of Information We Collect, How We Use Your Personal Information, How We Share and Disclose Information, Notifications and Marketing, Your data protection rights, Changes to Our Privacy Policy, Contact Us. <br /><br />

We affirm not to store, collect or process any or such sensitive information such as personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs; trade-union membership; genetic data, biometric data processed solely to identify a human being; health-related data; data concerning a person’s sex life or sexual orientation. <br />
We may collect both “Personal Information” and “Anonymous Information” about you when you use the Website, App and/or Service. Personal information is information that can be used to contact or identify you, such as your full name, date of birth, email address, phone number, payment method, billing information, profile picture, information about the vehicle (the registration number, model, year of manufacture and color of the vehicle), driver's license data as well as information that is linked to such information. Any information referred to as “Anonymous Information” is information that cannot be used to contact or identify you and is not linked to information that can be used to do so. It includes passively collected information about your activities on the Service, such as usage data, to the extent that information is not linked to your Personal Information. <br />
You can access and browse certain portions of the Website and download the App without disclosing your Personal Information, although, like most website and mobile app providers, we passively collect certain information from your devices, such as your IP address, browser information, unique device identifier (“UDID”) and/or your mobile operating system. Please note that you can choose not to provide us with certain information, but this will limit the features of the Service you can access and use. In order to utilize the Service, you must register with wehaul through the Application. Anyone who registers with Wehaul is a driver. <br /><br />

Should you be using our website with a device other than a mobile or handheld we collect information from you, including your hardware model, browser type, operating system, Internet Protocol (IP) and domain name. If you are using a handheld device, we may also receive your UDID, or another unique identifier, and mobile operating system. We have a right to pair this information with other information we may have gathered about you. We will also use cookies and URL information to collate information referring to date and time you used the Service and the information for which you searched and accessed. “Cookies” are small pieces of information that a website sends to your device while you are viewing the website. We will use Cookies to provide you with a more personal and interactive experience. Persistent Cookies can be removes by following general web a more personal and interactive experience. Persistent Cookies can be removed by following general web browser help file directions. <br />
In connection with your use of the Service, we may receive your call data, including the date and time of calls and SMS messages, the parties’ phone numbers and the content of SMS messages that relate <br /> <br />

If you log in to our platform with your social media credentials (e.g., Facebook or Twitter log-in), your Personal Information may be provided to us by such social media site. <br />
If you choose to link your app account with a payment provider, your Personal Information may be provided to us by such payment provider. <br /><br />

You must be at least 18 years of age to become a user. We will not be liable should any Personal Information from individuals under 18 years of age be provided to us. When discovered that a driver is under 18 years of age, we shall immediately take steps to remove that individual’s Personal Information from our systems and delete the account. <br /><br /><br />



We collect information that you provide to us during your use of the Website, App and/or Service, such as: <br />
Personal Information that you enter when registering, including your full name, email address, phone number, payment method, billing information, profile picture, information about the vehicle (the registration number, model, year of manufacture and color of the vehicle), driver's license data. <br />
Communications with us or with other Riders of drivers of vehicles using the Service (“Drivers”). <br />
Information you provide in your profile preferences, to customer support services or that you post on any forums or message boards in the App. <br /><br />

<strong><h2>Location Tracking</h2></strong>Please note that this app collects your approximate location whenever you are online and uses this to send you targeted requests. Also note that we DO NOT share your location with third party or use it for any other reasons other than optimizing the kinds of requests you get and altogether better user experience. This is a mandatory functionality of the application. If you access the Service through a mobile device or handheld device, we are likely to access, collect, monitor and/or remotely store “location data,” which may include the GPS coordinates of your trip (including pick-up and drop-off data) or similar information regarding the location of your mobile device. <br />
Transacting data: We collect information created during your various interactions with the Service, including the date and time or a pick up booked through the Service, distance of delivery and amount charged by the Driver. <br /><br />

Aggregated Anonymous Information is the combination of your Anonymous Information with the Anonymous Information of other users (“Aggregated Anonymous Information”). Aggregated Anonymous Information does not allow you to be identified or contacted. We may share such Aggregated Anonymous Information with third parties, and, depending on the circumstances, we may or may not charge third parties for such information, or limit the third parties’ use of the same. <br />
We may use Anonymous Information for the following purposes: <br />
Improve the Website, App and Service and customize your experience, such as by providing targeted useful features and promotions based on the type of services you seek. <br />
Aggregate the information collected via Cookies and similar technologies to use in statistical analysis to help us truck trends, evaluate the effectiveness of our ads and analyze patterns. <br /><br />

Safeguarding your information of utmost importance to us. We affirm to  deploy all reasonable administrative, physical and electronic measures designed to protect your information from unauthorized access. We will make any legally required disclosures of any breach of the security, confidentiality, or integrity of your unencrypted electronically stored “personal data” (as defined in applicable state statutes on security breach notification) to you via email, text or conspicuous posting on the Website in the most expedient time possible and without unreasonable delay, insofar as it is consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system. Although guaranteed security does not exist either on or off the Internet, we make commercially reasonable efforts to make the collection and security of such information consistent with this Privacy Policy and all applicable laws and regulations. <br /><br />

We understand that it is vital to keep your information confidential. We will not rent, sell or share your Personal Information with third parties except as specifically approves by you at the time of disclosure or under the circumstances described below. <br />
If you do not want us to use or disclose Personal Information collected about you in the ways identified in this Privacy Policy, you may choose not to (a) provide your Personal Information at any time or (b) download the App and become a dispatcher. <br />
In addition to using the information collected by us for the purposes described above, we may also share your information as described below. By becoming a dispatcher, you agree to allow us to share the Personal Information you provide to us in the ways described in our Privacy Policy. <br /><br />

We share certain information about Riders (e.g., name, rating, pick-up and drop-off locations) with Drivers to enable the scheduling and provision of Rides. <br />
Information provided on any of our online forum or message board may be viewed by any users or Dispatchers who access the forum or board in the App. <br /><br />

members from connecting Personal Information to information they collect through cookies without your explicit consent. <br />
To ensure you are fully briefed of your data protection rights. Every user is entitled to the following: <br />
The right to access – You have the right to request us for copies of your personal data. We may charge you a fee for this service.<br />
The right to update – You have the right to request that we correct any information you believe is inaccurate.<br />
The right to deletion – You have the right to request that we erase your personal data, under certain conditions.<br />
The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.<br />
The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.<br />
The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.<br />
If you make a request, we have 30 days to respond to you. If you would like to exercise any of these rights, please contact us at our email provided at the bottom of this document.<br /><br />

We may retain certain data after receiving an account deletion request for 10 years if necessary, such as to comply with legal requirements or use it for purposes of safety, security, fraud prevention and detection, and research and development.<br />
You may update your Personal Information by adjusting your account settings in the app. <br />
You can request the deletion of your Personal Information at any time. <br /><br />

As an organization we reserve the statutory right to change, modify, add or remove portions of this Privacy Policy at any time, and any changes will become effective immediately upon being posted unless we advise you otherwise. However, we will not use your Personal Information in a way that is materially different from the uses described in this Privacy Policy without giving you an opportunity to opt out. If we make significant changes, we will notify you in advance of the changes through the Website and/or App or through other means, such as email.<br />
Your continued use of the Service after this Privacy Policy has been amended shall be deemed to be your continued acceptance of the terms and conditions of the Privacy Policy, as amended. We encourage you to bookmark this webpage and review this Privacy Policy regularly.<br />
<strong>Contact Us</strong><br />
If you have any questions regarding our Privacy Policy, you can send a mail to us. We will be happy t respond to you in nothing more than 30 days.

                  </p> 
        </div>
    )
}

export default DriverPrivacy;