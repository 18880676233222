import './vehicles.scss';
import Bike from '../../assets/Vehicle Icons/bike.png';
import Salon from '../../assets/Vehicle Icons/saloon.png';
import Suv from '../../assets/Vehicle Icons/suv.png';
import Pickup from '../../assets/Vehicle Icons/pick-up.png';
import Van from '../../assets/Vehicle Icons/van.png';
import ImgHerobg from '../../assets/newbg.jpg';
import Truck from '../../assets/Vehicle Icons/truck.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';

const Vehicles = () => {
    const [shown, setShown] = useState(true)
    const history = useHistory();

    console.log(navigator.userAgent)

    const handleApps = () => {
        if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
          window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul247apps.driver";
        } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
          window.location.href = 'http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8';
        } else return
      }
    const handleApps2 = () => {
        if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
          window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul.customer";
        } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
          window.location.href = 'http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8';
        } else return
      }

    return (
        <div className="vehicles">
            <div className="topix">
                <div className="header">
                    <h2 className="bld">Find Multiple Delivery Options</h2>
                </div>
                <div className="icons">
                    <div className="grp">
                        <img src={Bike} alt="bike-icon" />
                        <p className="txt">Bikes</p>
                    </div>
                    <div className="grp">
                    <img src={Salon} alt="bike-icon" />
                        <p className="txt">Saloon Cars</p>
                    </div>
                    <div className="grp">
                    <img src={Suv} alt="bike-icon" />
                        <p className="txt">SUVs</p>
                    </div>
                    <div className="grp">
                    <img src={Pickup} alt="bike-icon" />
                        <p className="txt">Pick-Ups</p>
                    </div>
                    <div className="grp">
                    <img src={Van} alt="bike-icon" />
                        <p className="txt">Vans</p>
                    </div>
                    <div className="grp">
                    <img src={Truck} alt="bike-icon" />
                        <p className="txt">Trucks</p>
                    </div>
                    
                </div>
            </div>
            

            {shown && <div className="user-types">
                <div className="left">
                    <div className="top">
                        <p className="title">For Customers</p>
                        {/* <p className="info">Get Started</p> */}
                        <div className="action">Request Item for Pickup</div>
                    </div>
                    <div className="bottom">
                        <button onClick={handleApps2} className='diff'>Download App<i className="fas fa-arrow-right"></i></button>
                        {/* <button onClick={() => window.location.href = "https://customer.wehaul247.com"}>Register<i className="fas fa-arrow-right"></i></button> */}
                    </div>
                </div>
                <div className="right">
                    <div className="top">
                            <p className="title">For <br /> Drivers</p>
                            <p className="info">Make Extra Income Delivering Items</p>
                        </div>
                        <div className="bottom">
                            <button onClick={handleApps} className='diff'>Download App<i className="fas fa-arrow-right"></i></button>
                            <button onClick={() => history.push('/driver')}>Find Out More<i className="fas fa-arrow-right"></i></button>
                        </div>
                    </div>
            </div>}
        </div>
    )
}

export default Vehicles;