import React, { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import "./online.scss";
import routesxy from "../../../static/tempx";
// import { useSelector } from "react-redux";
// import RippleLoader from "../../components/rippleLoader/rippleLoader";
// import { updateUserProfile } from "../../services/settingsService";
import banksData from "./banks";
// import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import axios from "axios";
// import { baseUrl } from "../../config.json";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ButtonLoader from "../../../components/buttonLoader/buttonLoader";
import Compressor from "compressorjs";
import vehiclesData from "../vehicles";
import states from "./states";
import SuccessM from "../successModal/successM";

// const baseUrl = "http://wehaultestserver-env-1.eu-west-2.elasticbeanstalk.com/api/v1";
// const baseUrl = "https://wehaul-nig.herokuapp.com/api/v1";
const baseUrl = "https://app.wehaul247.com/api/v1";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
  },
};

const Online = () => {
  const [pLoader, setPLoader] = useState(false);
  const [dLoader, setDLoader] = useState(false);
  const [vLoader, setVLoader] = useState(false);
  const [aLoader, setALoader] = useState(false);
  const [d1Loader, setD1Loader] = useState(false);
  const [d2Loader, setD2Loader] = useState(false);
  const [finalLoader, setFinalLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [resType, setResType] = useState("");

  const [brand, setBrand] = useState("");
  const [tempModel, setTempModel] = useState([]);
  const [model, setModel] = useState([]);
  const [smartPhone, setSmartPhone] = useState(null);
  const [downloaded, setDownloaded] = useState(null);
  const [vehicleTypex, setVehicleTypex] = useState("");
  const [vehicleLoc, setVehicleLoc] = useState("");

  const [role, setRole] = useState("");
  const [number, setNumber] = useState("");
  const [wNumber, setWNumber] = useState("");
  const [emailr, setEmailr] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [refCode, setRefCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loadingr, setLoadingr] = useState(false);
  const [fullname, setFullname] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const formData = {
    name: fullname,
    phoneNumber: number,
    whatsAppNumber: wNumber,
    email: emailr,
    referralCode: refCode,
    password: password,
    userClass: "driver",
  };
  const formData2 = {
    name: fullname,
    phoneNumber: number,
    whatsAppNumber: wNumber,
    email: emailr,
    password: password,
    userClass: "driver",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const verify = {
      email,
      userClass: "driver",
    };
    if (password === confirmPassword) {
      if (refCode.length > 1) {
        setLoading(true);
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/sign-up`,
            formData
          );
          setLoading(false);
          toast.success(data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setSec1(false)
          setSec2(true)
          localStorage.setItem("ONBOARDING_TOKEN", data?.token);
        } catch (error) {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        setLoading(true);
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/sign-up`,
            formData2
          );
          setLoading(false);
          toast.success(data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          localStorage.setItem("ONBOARDING_TOKEN", data?.token);
        } catch (error) {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } else {
      toast.error("password mismatch", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }

    // else if(number.length < 11) {
    //     toast.error('invalid phone number', {
    //         position: toast.POSITION.TOP_CENTER
    //     })
  };

  function Button() {
    if (
      fullname.length &&
      emailr.length &&
      number.length &&
      password.length >= 8 &&
      confirmPassword.length &&
      password === confirmPassword &&
      refCode.length
    ) {
      return (
        <>
          <button type="submit">{loading ? "Signing Up..." : "Sign Up"}</button>
          <br />
          <br />
        </>
      );
    } else {
      return (
        <>
          <button disabled type="submit">
            Sign up
          </button>
          <br />
          <br />
        </>
      );
    }
  }

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankName, setBankName] = useState("");

  const [compressedFile, setCompressedFile] = useState(null);

  const [ppUploadBtn, setppUploadBtn] = useState(false);

  const [profilePic, setProfilePic] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  const [routes, setRoutes] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  // const [loading, setLoading] = useState(false);

  const [dType, setDType] = useState("");

  const [plateNumber, setPlateNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vLicenseNumber, setVLicenseNumber] = useState("");
  const [vIssueDate, setVIssueDate] = useState("");
  const [vExpiryDate, setVExpiryDate] = useState("");
  const [vLicenseImg, setVLicenseImg] = useState("");
  const [goodsImagePrev2, setGoodsImagePrev2] = useState([]);
  const [vImg, setVImg] = useState("");
  const [goodsImagePrev3, setGoodsImagePrev3] = useState([]);

  //   const [accountName, setAccountName] = useState('');
  // const [accountNumber, setAccountNumber] = useState('');
  // const [accountType, setAccountType] = useState('');
  // const [bankName, setBankName] = useState('');

  const [regCert, setRegCert] = useState("");
  const [vehicleInsurance, setVehicleInsurance] = useState("");
  const [carrierPermit, setCarrierPermit] = useState("");
  const [touristTaxi, setTouristTaxi] = useState("");
  const [roadWorth, setRoadWorth] = useState("");
  const [hackeyPermit, setHackneyPermit] = useState("");

  const [sec1, setSec1] = useState(true);
  const [sec2, setSec2] = useState(false);
  const [sec3, setSec3] = useState(false);
  const [sec4, setSec4] = useState(false);
  const [sec5, setSec5] = useState(false);

  // const [loading, setLoading] = useState(false);
  // const [loading2, setLoading2] = useState(false);

  const doci = new FormData();

  doci.append("regCertImage", regCert);
  doci.append("vehicleInsuranceImage", vehicleInsurance);
  doci.append("carriagePermitImage", carrierPermit);

  const doci2 = new FormData();

  doci2.append("touristTaxiPermitImage", touristTaxi);
  doci2.append("roadworthinessCertImage", roadWorth);
  doci2.append("hackneyPermitImage", hackeyPermit);

  const handleDocSubmit = async (e) => {
    e.preventDefault();
    setD1Loader(true);
    try {
      const { data } = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        doci,
        config
      );
      console.log(data);
      toast.success(data.message);
      setD1Loader(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      setD1Loader(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDocSubmit2 = async (e) => {
    e.preventDefault();
    setD2Loader(true);
    try {
      const { data } = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        doci2,
        config
      );
      console.log(data);
      toast.success(data.message);
      setD2Loader(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      setD2Loader(false);
      toast.error(error?.response?.data?.message);
    }
  };

  

  const vformData = new FormData();
  vformData.append("vehicleInformationAndLicense[plateNumber]", "xxxxxx");
  vformData.append("vehicleInformationAndLicense[vehicleType]", vehicleType);
  vformData.append("vehicleInformationAndLicense[issueDate]", "xxxxxx");
  vformData.append("vehicleInformationAndLicense[expiryDate]", "xxxxxx");
  vformData.append(
    "vehicleInformationAndLicense[vehicleLicenseNumber]",
    "xxxxxx"
  );
  vformData.append("profilePicture", vLicenseImg);
  vformData.append("vehicleImages", vImg);

  const vehicletempData = vformData;

  const handleVehicleLicenseSumbit = async (e) => {
    e.preventDefault();
    setVLoader(true);
    console.log(vehicletempData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        vehicletempData,
        config
      );
      console.log(res.data);
      setVLoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      
      // window.location.reload(false);
    } catch (error) {
      console.log(error.response.data.message);
      setVLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [dLicenseNumber, setDLicenseNumber] = useState("");
  const [dIssueDate, setDIssueDate] = useState("");
  const [dExpiryDate, setDExpiryDate] = useState("");
  const [dLicenseImg, setDLicenseImg] = useState("");
  const [goodsImagePrev1, setGoodsImagePrev1] = useState([]);

  const dformData = new FormData();
  dformData.append(
    "driverLicenseInformation[driverLicenseNumber]",
    dLicenseNumber
  );
  dformData.append("driverLicenseInformation[issueDate]", dIssueDate);
  dformData.append("driverLicenseInformation[expiryDate]", dExpiryDate);
  dformData.append("driverLicenseImage", dLicenseImg);

  const drivertempData = dformData;

  const handleDriverLicenseSumbit = async (e) => {
    e.preventDefault();
    setDLoader(true);
    console.log(drivertempData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        drivertempData,
        config
      );
      console.log(res.data);
      setDLoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // window.location.reload(false);
    } catch (error) {
      console.log(error.response.data.message);
      setDLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [selected, setSelected] = useState([]);
  const [ids, setIds] = useState([]);
  const [rLoader, setRLoader] = useState(false);

  const handleRoutes = (i) => {
    setSelected((prev) =>
      selected.includes(i) ? prev.filter((cur) => cur !== i._id) : [...prev, i]
    );
    if (!ids.includes(i._id)) {
      setIds([...ids, i._id]);
    } else {
      alert("you cannot select this again");
    }
  };

  const allSelectedRoutes = {
    route: selected,
  };

  const addNewRoutes = async (e) => {
    e.preventDefault();
    setRLoader(true);
    setShowModal(true);
    try {
      const res = await axios.put(
        `${baseUrl}/driver/add-preferred-route`,
        allSelectedRoutes,
        config
      );
      console.log(res.data);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
      setMsg(res?.data?.message);
      setResType("success")
      setSec4(false)
      setSec1(true)
      //  window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
    }
  };

  const finalData = new FormData();

  // finalData.append("phoneNumber", phoneNumber)
  // finalData.append("address", address);
  // finalData.append("name", name);
  // finalData.append("email", email);
  // finalData.append("driverLicenseInformation[driverLicenseNumber]", dLicenseNumber);
  // finalData.append("driverLicenseInformation[issueDate]", dIssueDate);
  // finalData.append("driverLicenseInformation[expiryDate]", dExpiryDate);
  // finalData.append("vehicleInformationAndLicense[plateNumber]", plateNumber);
  // finalData.append("vehicleInformationAndLicense[vehicleType]", vehicleType);
  // finalData.append("vehicleInformationAndLicense[issueDate]", vIssueDate);
  // finalData.append("vehicleInformationAndLicense[expiryDate]", vExpiryDate);
  // finalData.append("vehicleInformationAndLicense[vehicleLicenseNumber]", vLicenseNumber);
  // finalData.append("accountInformation[bankName]", bankName);
  // finalData.append("accountInformation[accountName]", accountName);
  // finalData.append("accountInformation[accountNumber]", accountNumber);
  // finalData.append("accountInformation[accountType]", accountType);
  // finalData.append("deliveryTypes", dType);
  // finalData.append("preferredRoutes", routes);
  // finalData.append("driverLicenseImage", dLicenseImg);
  // finalData.append("vehicleLicenseImage", vLicenseImg);
  // finalData.append("vehicleImages", vImg);
  // finalData.append("regCertImage", regCert)
  // finalData.append("vehicleInsuranceImage", vehicleInsurance)
  // finalData.append("carriagePermitImage", carrierPermit)
  // finalData.append("touristTaxiPermitImage", touristTaxi)
  // finalData.append("roadworthinessCertImage", roadWorth)
  // finalData.append("hackneyPermitImage", hackeyPermit)

  const handleOfflineSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(finalData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.WEHAUL_DRIVER_TOKEN}`,
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/wehaul/driver/create`,
        finalData,
        config
      );
      console.log(res.data);
      setLoading(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      window.location.reload();
    } catch (error) {
      console.log(error.response.data.message);
      setLoading(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const personalData = {
    phoneNumber: phoneNumber,
    address: address,
  };

  const handlePersonalSubmit = async (e) => {
    e.preventDefault();
    setPLoader(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        personalData,
        config
      );
      console.log(res.data);
      setPLoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error.response.data.message);
      setPLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const tempData = {
    accountInformation: {
      accountName,
      accountNumber,
      accountType,
      bankName,
    },
  };

  const handleAcctSumbit = async (e) => {
    e.preventDefault();
    setALoader(true);
    console.log(tempData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        tempData,
        config
      );
      console.log(res.data);
      setALoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error.response.data.message);
      setALoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  //   const handlePPchange = (e) => {
  //     setppUploadBtn(true);
  //     setProfilePic(e.target.files[0]);
  //   };

  const uploadImg = async (e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    const formData = new FormData();
    formData.append("profilePicture", compressedFile);
    setLoadingx(true);

    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        formData,
        config
      );
      console.log(res.data);
      setLoadingx(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setppUploadBtn(false);
      // window.location.reload();
    } catch (error) {
      console.log(error);
      setLoadingx(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        setppUploadBtn(true);
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setCompressedFile(compressedResult);
        console.log(compressedResult);
      },
    });
  };

  const clearToken = async () => {
    setFinalLoader(true);
    await localStorage.removeItem("ONBOARDING_TOKEN");
    window.location.reload();
    window.scrollTo(0, 0);
  };

  // const vehiclesDatax = vehiclesData.map(i => i.brand).map()

  const [docs, setDocs] = useState([]);

  // const handleRoutes = (e) => {
  //   let newArray = [...routes, e.target.value];
  //   if(routes.includes(e.target.value)) {
  //     newArray = newArray.filter(d => d !== e.target.value)
  //   }
  //   setRoutes(newArray)
  // };

  const handleDocs = (e) => {
    let newArray = [...docs, e.target.value];
    if (docs.includes(e.target.value)) {
      newArray = newArray.filter((d) => d !== e.target.value);
    }
    setDocs(newArray);
  };

  const selectBrand = (e) => {
    setBrand(e.target.value);
    setTempModel(vehiclesData.find((i) => i.brand === e.target.value).models);
  };
  const selectModel = (e) => {
    setModel(e.target.value);
    // setModel(vehiclesData.find(i => i.brand === e.target.value).models);
  };

  const showRes = () => {
    console.log(selected);
  };

  // const dataz = new FormData();

  // dataz.append("vehicleBrand", brand)
  // dataz.append("vehicleModel", model)
  // dataz.append("isDownloaded", true)
  // dataz.append("vehicleLocation", vehicleLoc)
  // dataz.append("providedDocs", docs)
  // dataz.append("name", fullname)
  // dataz.append("phoneNumber", number)
  // dataz.append("email", emailr)
  // dataz.append("providedDocs", docs)

  const datazy = {
    vehicleBrand: brand,
    vehicleModel: model,
    isDownloaded: downloaded,
    vehicleLocation: vehicleLoc,
    providedDocs: docs,
    name: fullname,
    phoneNumber: number,
    referralCode: refCode
    // email: emailr,
  };

  const dataMerge = async (e) => {
    e.preventDefault();
    setPLoader(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.WEHAUL_DRIVER_TOKEN}`,
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/wehaul/driver/create`,
        datazy,
        config
      );
      console.log(res.data);
      setPLoader(false);
      setMsg(res?.data?.message);
      setResType("success");
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSec2(false)
      setSec3(true)
      // window.location.reload();
    } catch (error) {
      console.log(error?.response?.data?.message);
      setPLoader(false);
      setResType("failure");
      setMsg(error?.response?.data?.message);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [vLoaderx, setVLoaderx] = useState(false);


  const vt = new FormData();
  vt.append("vehicleInformationAndLicense[vehicleType]", vehicleType);
  vt.append("vehicleInformationAndLicense[plateNumber]", "xxxxxx");
  vt.append("vehicleInformationAndLicense[issueDate]", "xxxxxx");
  vt.append("vehicleInformationAndLicense[expiryDate]", "xxxxxx");
  vt.append("vehicleInformationAndLicense[vehicleLicenseNumber]","xxxxxx");

  const uploadVt = async (e) => {
    e.preventDefault();
    setVLoaderx(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        vt,
        config
      );
      console.log(res.data);
      setVLoaderx(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // window.location.reload(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      setVLoaderx(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleCompressedUpload1 = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setVLicenseImg(compressedResult);
        console.log(compressedResult);
      },
    });
  };
  const handleCompressedUpload2 = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setVImg(compressedResult);
        console.log(compressedResult);
      },
    });
  };

  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [cloading, setCloading] = useState(false);
  const [cloading2, setCloading2] = useState(false);

  const uploadMedia1 = async () => {

    const datam = new FormData();
    datam.append("file", profilePic);
    datam.append("upload_preset", "onboarding");
    datam.append("cloud_name", "Wehaul Logistics");

    setCloading(true);

    try {
      const res = await axios.post("https://api.cloudinary.com/v1_1/wehaul-logistics/image/upload", datam)
      console.log(res)
      setUrl1(res?.data?.url)
      setCloading(false)
      if(res.status === 200) {
        toast.success("image saved successfully")
      }
    } catch (err) {
      console.log(err)
      toast.error("An error occured, please check network connectivity")
      setCloading(false)
    }
  };
  const uploadMedia2 = async () => {

    const datam = new FormData();
    datam.append("file", vImg);
    datam.append("upload_preset", "onboarding");
    datam.append("cloud_name", "Wehaul Logistics");

    setCloading2(true);

    try {
      const res = await axios.post("https://api.cloudinary.com/v1_1/wehaul-logistics/image/upload", datam)
      console.log(res.data)
      setUrl2(res?.data?.url)
      setCloading2(false)
      if(res.status === 200) {
        toast.success("image saved successfully")
      }
    } catch (err) {
      console.log(err)
      toast.error("An error occured, please check network connectivity")
      setCloading2(false)
    }
  };


  const [viLoader, setVILoader] = useState(false);

  const vimages = new FormData();
  vimages.append("profilePicture", url1);
  vimages.append("vehicleImages", url2);
  vimages.append("vehicleImages", url2);

  const addVImages = async (e) => {
    e.preventDefault();
    setVILoader(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        vimages,
        config
      );
      console.log(res.data);
      setVILoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSec3(false)
      setSec4(true)
      // window.location.reload(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      setVILoader(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div className="settings">
      {showModal && (
        <SuccessM
          msg={msg}
          resType={resType}
          loading={rLoader}
          setTokenBox={setShowModal}
        />
      )}
      {sec1 && <div className="container">
        <div className="left"></div>
        <div className="right">
          <form onSubmit={handleSubmit}>
            <h3>Sign up</h3>
            <input
              type="text"
              placeholder="Full Name"
              required
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />{" "}
            <br />
            <input
              type="email"
              placeholder="Email"
              required
              value={emailr}
              onChange={(e) => setEmailr(e.target.value)}
            />{" "}
            <br />
            <input
              type="tel"
              placeholder="Phone Number"
              required
              minLength={11}
              maxLength={11}
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />{" "}
            <input
              type="tel"
              placeholder="Whatsapp Number"
              minLength={11}
              maxLength={11}
              value={wNumber}
              onChange={(e) => setWNumber(e.target.value)}
            />{" "}
            <br />
            <div className="p-word">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Re-enter Password"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye"
                ></i>
              )}
              {!showPassword && (
                <i
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="fas fa-eye-slash"
                ></i>
              )}
              <input
                type="text"
                placeholder="Promoter Referral Code"
                value={refCode}
                style={{ textTransform: "uppercase" }}
                onChange={(e) => setRefCode(e.target.value.toUpperCase())}
              />
              {password.length > 0 && (
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    // "match",
                  ]}
                  minLength={8}
                  value={password}
                  className="validation"
                  iconSize={11}
                  valueAgain={confirmPassword}
                  messages={{
                    minLength: "8 characters minimum",
                    specialChar: "Contains a special character",
                    number: "Contains a number",
                    capital: "Contains a capital number",
                    // match: "Passwords match",
                  }}
                />
              )}
              {confirmPassword.length > 0 && (
                <PasswordChecklist
                  rules={["match"]}
                  minLength={8}
                  value={password}
                  className="validation"
                  iconSize={11}
                  valueAgain={confirmPassword}
                  messages={{
                    match: "Passwords match",
                  }}
                />
              )}
            </div>
            <Button />
          </form>
        </div>
      </div>}

      <div className="cover">
        {sec2 && <form onSubmit={dataMerge} className="form">
          <div className="personal-info">
            <div className="inputs">
              <div className="input">
                <label htmlFor="phone-number">Vehicle Brand</label>
                <br />
                <i className="fas fa-circle"></i>
                <select
                  id="phone-number"
                  // value={brand}
                  onChange={selectBrand}
                >
                  <option selected disabled>
                    Select Vehicle Brand
                  </option>
                  {vehiclesData.map((i, index) => {
                    return (
                      <option key={index} value={i.brand}>
                        {i.brand}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="input">
                <label htmlFor="phone-number">Vehicle Model</label>
                <br />
                <i className="fas fa-circle"></i>
                <select
                  id="phone-number"
                  // value={model}
                  onChange={selectModel}
                >
                  <option selected disabled>
                    Select Vehicle Model
                  </option>
                  {tempModel.map((i, index) => {
                    return (
                      <option key={index} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="input">
                <label htmlFor="phone-number">
                  Has Driver downloaded the Mobile App ?
                </label>
                <br />
                <select
                  id="phone-number"
                  // value={model}
                  onChange={(e) => setDownloaded(e.target.value)}
                >
                  <option selected disabled>
                    --select--
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="input">
                <label htmlFor="phone-number">Vehicle Location</label>
                <br />
                <input
                  type="text"
                  value={vehicleLoc}
                  onChange={(e) => setVehicleLoc(e.target.value)}
                />
              </div>

              <br />
              <br />
              <div className="routes">
                <p className="title">Available Documents</p>
                <div className="route">
                  <input
                    type="checkbox"
                    value="Drivers License"
                    onChange={(e) => handleDocs(e)}
                  />
                  <p className="item">Drivers License</p>
                </div>
                <div className="route">
                  <input
                    type="checkbox"
                    value="Road Worthiness"
                    onChange={(e) => handleDocs(e)}
                  />
                  <p className="item">Road Worthiness</p>
                </div>
                <div className="route">
                  <input
                    type="checkbox"
                    value="Insurance"
                    onChange={(e) => handleDocs(e)}
                  />
                  <p className="item">Insurance</p>
                </div>
              </div>
            </div>
            <br />
            <button type="submit">{pLoader ? "Saving" : "Save"}</button>
          </div>
        </form>}

        {sec3 && <> <form onSubmit={handleVehicleLicenseSumbit} className="form">
          <div className="business-info">
            <div className="title">Vehicle Type</div>
            <div className="all-inputs">
              <div className="left-inputs">
                <div className="input">
                  <label htmlFor="address">Vehicle Type</label>
                  <br />
                  <select onChange={(e) => setVehicleType(e.target.value)}>
                    <option selected disabled></option>
                    <option value="bike">Delivery Bike</option>
                    <option value="Van">Van</option>
                    <option value="cab">Cab</option>
                    <option value="cabster">Cabster</option>
                    <option value="dyna">Dyna</option>
                    <option value="pickup">Pick-up</option>
                    <option value="flatbeds">Flatbeds</option>
                    <option value="coaster">Coaster</option>
                    <option value="hummer bus">Hummeer Bus</option>
                    <option value="bus">Bus</option>
                    <option value="space bus">Space Bus</option>
                    <option value="matrix">Matrix</option>
                    <option value="station wagon">Station Wagon</option>
                    <option value="saloon">Saloon</option>
                  </select>
                </div>
              </div>

              
            </div>
            <br />
            <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}} onClick={uploadVt}>{vLoaderx ? "Saving..." : "Save"}</p>
            {/* <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}} onClick={uploadMedia2}>{cloading2 ? "Saving..." : "Save"}</p> */}
          </div>
        </form>

        <form onSubmit={handleVehicleLicenseSumbit} className="form">
          <div className="business-info">
            <div className="title">Driver Picture</div>
            <div className="all-inputs">

              <div className="right-inputs">
                <div
                  className="input"
                  style={{ display: "flex", justifyContent: "space-between" }}
                ></div>

                <div className="files">
                  <div className="img-upload field-div">
                    <div className="selector">
                      <div className="action">
                        <input
                          onChange={e => setProfilePic(e.target.files[0])}
                          type="file"
                          id="file"
                        />
                        <br />
                        <br />
                        <p>Upload Driver Picture</p>
                      </div>
                      <div
                        style={{
                          height: "142px",
                          borderRadius: "0 0 5px 5px",
                          backgroundImage: `url(${goodsImagePrev2})`,
                          backgroundSize: "cover",
                        }}
                        className="preview"
                      ></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <br />
            {url1 ?  <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}}>Saved</p> : <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}} onClick={uploadMedia1}>{cloading ? "Saving..." : "Save"}</p>}
          </div>
        </form>

        <form onSubmit={handleVehicleLicenseSumbit} className="form">
          <div className="business-info">
            <div className="title">Vehicle Image</div>
            <div className="all-inputs">
        
              <div className="right-inputs">
                <div
                  className="input"
                  style={{ display: "flex", justifyContent: "space-between" }}
                ></div>

                <div className="files">
                  
                  <div className="img-upload field-div">
                    <div className="selector">
                      <div className="action">
                        <input
                          onChange={e => setVImg(e.target.files[0])}
                          type="file"
                          id="file"
                        />
                        <br />
                        <br />
                        <p>Upload Vehicle Image</p>
                      </div>
                      <div
                        style={{
                          height: "142px",
                          borderRadius: "0 0 5px 5px",
                          backgroundImage: `url(${goodsImagePrev3})`,
                          backgroundSize: "cover",
                        }}
                        className="preview"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            {url2 ?  <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}}>Saved</p> : <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}} onClick={uploadMedia2}>{cloading2 ? "Saving..." : "Save"}</p>}
          </div>
          <button onClick={addVImages}>{viLoader ? "Sending..." : "Send Images"}</button>
        </form></>}
        <br />
        <br />
        <br />

        {sec4 &&
        vehicleType !== "dyna" && (
          <div className="personal-info">
            <p className="title">Preferred Routes</p>
            <br />

            <div className="routes">
              {routesxy.map((i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "13px",
                      alignItems: "center",
                    }}
                    className="route"
                    key={i?._id}
                  >
                    <input
                      style={{ transform: "scale(1.7)", marginRight: "30px" }}
                      type="checkbox"
                      onChange={() => handleRoutes(i)}
                    />
                    <p style={{ fontWeight: "600" }} className="item">
                      {capitalizeFirstLetter(i?.name)}
                    </p>
                  </div>
                );
              })}
            </div>
            <button onClick={addNewRoutes}>
              {rLoader ? "Saving" : "Save"}
            </button>
          </div>
        )}

        {vehicleType === "dyna" && (
          <div className="personal-info">
            <p className="title">Preferred States</p>
            <br />

            <div className="routes">
              {states.map((i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "13px",
                      alignItems: "center",
                    }}
                    className="route"
                    key={i?._id}
                  >
                    <input
                      style={{ transform: "scale(1.7)", marginRight: "30px" }}
                      type="checkbox"
                      onChange={() => handleRoutes(i)}
                    />
                    <p style={{ fontWeight: "600" }} className="item">
                      {capitalizeFirstLetter(i)}
                    </p>
                  </div>
                );
              })}
            </div>
            
            <button onClick={addNewRoutes}>
              {rLoader ? "Saving" : "Save"}
            </button>
          </div>
        )}
        <br />
        {/* <div className="btn" style={{textAlign: "center"}}>
            <button onClick={clearToken} style={{padding: "20px 70px", fontSize: "18px"}}>
              {finalLoader ? "Please Wait..." : "Finish"}
            </button>
          </div> */}
        {/* </form> */}
      </div>
    </div>
  );
};

export default Online;
