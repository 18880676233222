import './hero.scss';
import Google from '../../assets/google-download.png';
import Apple from '../../assets/apple-download.png';
import Typist from 'react-typist';
import TextLoop from 'react-text-loop';
import ImgHero from './illu.svg';
import ImgHerobg from '../../assets/newbg.jpg';
import { useState } from 'react';
import Popup from './popup/popup';
import Gif from "./webgif2.gif";

const Hero = () => {
    const [userType, setUserType] = useState("customer");
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [showModal, setShowModal] = useState(false);
    

    const handleForm = (e) => {
        e.preventDefault();
        window.location.href = `https://${userType}.wehaul247.com/signup?name=${name}&email=${email}&phone=${phone}`
    }

    return (
        <div className="hero">
            <div className="left">
                {/* <Typist> */}
                <h1 className="bld-txt">Move your <TextLoop><span>Gas Cooker</span><span>Food Items</span><span>Books/Tools</span><span>Generator</span><span>Pets/Animals</span></TextLoop> <br /> from one location to the other</h1>
                <div className="flex-line">
                        
                        
                        <div className="imgs">
                            <a href="http://play.google.com/store/apps/details?id=com.wehaul.customer" target="_blank" rel="noopener noreferrer"><div style={{marginRight: "50px", backgroundImage: `url(${Google})`, transform: "scale(1.4)"}} className="imgx"></div></a>
                            <a href="https://apps.apple.com/ng/app/wehaul/id1618324644" target="_blank" rel="noopener noreferrer"><div style={{backgroundImage: `url(${Apple})`, transform: "scale(1.4)"}} className="imgx"></div></a>
                        </div>
                        <p className="or">or</p>
                    </div>
             
                <form onSubmit={handleForm}>
                    <div className="inp">
                        <select onChange={e => setUserType(e.target.value)}>
                            <option selected value="customer">Personal</option>
                            <option value="merchant">Business</option>
                        </select>
                        <i className="fas fa-user"></i>
                    </div>
                    <div className="inp">
                        <input onChange={e => setName(e.target.value)} type="text" placeholder="Enter Full Name" />
                        <i className="fas fa-user"></i>
                    </div>
                    <div className="inp">
                        <input onChange={e => setEmail(e.target.value)} type="email" placeholder="Enter Email Address" />
                        <i className="fas fa-envelope"></i>
                    </div>
                    <div className="flex">
                        <div className="inp" style={{width: "52%"}}>
                            <input onChange={e => setPhone(e.target.value)} minLength={11} maxLength={11} type="text" placeholder="Enter Phone Number" />
                            <i style={{color: "#0bb10b"}} className="fas fa-phone"></i>
                        </div>
                        <div style={{cursor: "pointer"}} onClick={handleForm} className="btn">
                                <button>Request Pick-Up</button>
                                <i className="fas fa-arrow-right"></i>
                        </div>
                    </div>
                    
                    
                    
                    
                </form>
                {showModal && <Popup setShowModal={setShowModal} />}
                <div className="imgs">
                            <a href="http://play.google.com/store/apps/details?id=com.wehaul.customer" target="_blank" rel="noopener noreferrer"><div style={{marginRight: "50px", backgroundImage: `url(${Google})`, transform: "scale(1.4)"}} className="imgx"></div></a>
                            <a href="https://apps.apple.com/ng/app/wehaul/id1618324644" target="_blank" rel="noopener noreferrer"><div style={{backgroundImage: `url(${Apple})`, transform: "scale(1.4)"}} className="imgx"></div></a>
                </div>
            </div>
            <div className="right">
                <img style={{width: "100%"}} src={Gif} alt="website-header" />
                {/* <video src={Gif} autoPlay></video> */}
            </div>
            {/* <div className="right" style={{backgroundImage: `url(${ImgHe})`}}>
                <div className="content">
                     <h1 className="bld-txt">Move your Gas Cooker from one location to the other</h1>
                <p className="para">Register or Request to move an item now</p>
                <form>
                    <input type="text" placeholder="Enter item name" />
                    <input type="text" placeholder="Enter item pick-up address" />
                    <input type="text" placeholder="Enter item delivery address" />
                    <div className="flex-line">
                        <button type="">Request Pick-Up</button>
                        <p className="or">OR</p>
                        <div className="imgs">
                            
                        </div>
                    </div>
                    
                </form>
                
                </div>
           
            </div> */}
        </div>
    )
}

export default Hero;